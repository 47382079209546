<template>
  <div>
    <!-- Info Alert Modal -->
    <b-modal
      id="info-alert-modal"
      body-class="p-4"
      hide-header
      hide-footer
      size="sm"
    >
      <div class="text-center">
        <i class="bx bxs-info-circle h1 text-info"></i>
        <h4 class="mt-2">Heads up!</h4>
        <p class="mt-3">
          Cras mattis consectetur purus sit amet fermentum. Cras justo
          odio, dapibus ac facilisis in, egestas eget quam.
        </p>
        <button
          type="button"
          class="btn btn-info my-2"
          data-dismiss="modal"
        >
          Continue
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
  export default {
    data() {
      return {
        isVerified: false,
        loading: false
      }
    },

    mounted() {
      this.verifyToken();
    },

    methods: {
      ...mapActions('authModule', ['verifyPasswordChange']),

      async verifyToken() {
        this.loading = true;

        try {
          const { token } = this.$route.query;
          const { data } = await this.verifyPasswordChange({ token });
          this.isVerified = data;
        } catch (error) {
          
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>

<style scoped>

</style>